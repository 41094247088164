import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Gender,
  Participant,
  ParticipantStatus,
  PiccoloError,
  UpdateParticipantRequest,
} from '@piccolohealth/pbs-common';
import { Spin } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { HookedSubmitButton } from '../../components/forms/HookedSubmitButton';
import { Error } from '../../components/generic/Error';
import { createModal } from '../../components/generic/Modal';
import { useParticipantQuery } from '../../graphql/hooks/useParticipantQuery';
import { useUpdateParticipantMutation } from '../../graphql/hooks/useUpdateParticipantMutation';
import { useAppContext } from '../../hooks/useAppContext';
import { dateTimeSchema, stringToNull } from '../../utils/zod';
import { ParticipantForm } from './ParticipantForm';
import { ParticipantVersionStatus } from './ParticipantVersionStatus';

type FormValues = UpdateParticipantRequest;

const schema: z.ZodSchema<FormValues> = z.object({
  firstName: z.string(),
  lastName: z.string(),
  dob: dateTimeSchema,
  gender: z.nativeEnum(Gender),
  address: z.string().nullish(),
  postcode: z.string().nullish(),
  suburb: z.string().nullish(),
  state: z.string().nullish(),
  locationId: z.string().nullish(),
  labelIds: z.array(z.string()),
  status: z.nativeEnum(ParticipantStatus),
  ndisNumber: z.string().nullish(),
  picture: z.string().nullish(),
  keyContacts: z.array(
    z.object({
      id: z.string(),
      name: z.string(),
      phone: stringToNull(z.string().nullish()),
      email: stringToNull(z.string().email().nullish()),
      description: stringToNull(z.string().nullish()),
      labelIds: z.array(z.string()),
    }),
  ),
  fieldGroups: z.array(
    z.object({
      id: z.string(),
      fields: z.array(
        z.object({
          id: z.string(),
          value: z.any(),
        }),
      ),
    }),
  ),
});

interface Props {
  participantId: string;
  isDisabled?: boolean;
  openToTabIndex?: number;
}

export const ParticipantEditModal = createModal<Props>((props) => {
  const { modal, participantId, isDisabled, openToTabIndex } = props;
  const { errorToast, successToast, organization } = useAppContext();

  const { hide, remove, visible } = modal;

  const { data, isLoading, error } = useParticipantQuery({
    organizationId: organization.id,
    participantId: props.participantId,
  });

  const participant = data?.organization?.participant as Participant | undefined;

  const defaultValues: FormValues = React.useMemo(() => {
    return {
      firstName: participant?.firstName,
      lastName: participant?.lastName,
      dob: participant?.dob,
      gender: participant?.gender,
      address: participant?.address,
      postcode: participant?.postcode,
      suburb: participant?.suburb,
      state: participant?.state,
      locationId: participant?.location?.id,
      labelIds: participant?.labels?.map((label) => label.id) ?? [],
      status: participant?.status,
      ndisNumber: participant?.ndisNumber,
      picture: participant?.picture,
      fieldGroups: participant?.fieldGroups,
      keyContacts:
        participant?.keyContacts?.map((keyContact) => {
          const labelIds = keyContact.labels.map((label) => label.id) ?? [];
          return { ...keyContact, labelIds };
        }) ?? [],
    };
  }, [participant]);

  const methods = useForm<FormValues>({
    values: defaultValues,
    mode: 'all',
    resolver: zodResolver(schema),
  });

  const updateParticipantMutation = useUpdateParticipantMutation();

  const onSubmit = React.useCallback(
    async (values: FormValues) => {
      const request = P.deepObjectDiff(defaultValues, values);

      await updateParticipantMutation
        .mutateAsync({
          organizationId: organization.id,
          participantId: participantId,
          request: P.deepTrim(request),
        })
        .then(() => {
          hide();
          successToast('Participant updated successfully');
        })
        .catch((err) => {
          errorToast(`Error updating participant: ${err.message}`);
        });
    },
    [
      updateParticipantMutation,
      organization.id,
      participantId,
      defaultValues,
      hide,
      successToast,
      errorToast,
    ],
  );

  const content = P.run(() => {
    if (isLoading) {
      return <Spin />;
    }

    if (error) {
      return <Error error={error} />;
    }

    if (!participant) {
      return (
        <Error
          error={
            new PiccoloError({
              type: 'ParticipantNotFound',
              message: 'Participant not found',
            })
          }
        />
      );
    }

    return <ParticipantForm isDisabled={isDisabled} openToTabIndex={openToTabIndex} />;
  });

  const participantVersionStatus = P.run(() => {
    if (P.isNil(participant)) {
      return null;
    }

    return (
      !participant.isOnLatestFields &&
      !isDisabled && <ParticipantVersionStatus participantId={participantId} />
    );
  });

  return (
    <Modal isOpen={visible} onClose={hide} onCloseComplete={remove} size="4xl">
      <ModalOverlay />
      <ModalContent>
        <FormProvider {...methods}>
          <Box as="form" display="contents" onSubmit={methods.handleSubmit(onSubmit)} noValidate>
            <ModalHeader>
              <Stack spacing={4}>
                <Flex align="start">
                  <Box>
                    <Heading size="md">Edit a participant</Heading>
                    <Text fontSize="md" fontWeight="normal" color="secondary" mt={2}>
                      Please complete the following to proceed
                    </Text>
                  </Box>
                  <Spacer />
                  <ModalCloseButton position="unset" top="unset" right="unset" />
                </Flex>
                {participantVersionStatus}
              </Stack>
            </ModalHeader>
            <ModalBody>{content}</ModalBody>
            <ModalFooter>
              <HStack>
                <Button size="sm" onClick={hide}>
                  Close
                </Button>
                <HookedSubmitButton size="sm">Save</HookedSubmitButton>
              </HStack>
            </ModalFooter>
          </Box>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
});
