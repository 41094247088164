import { Box, Divider, Heading, HStack, IconButton, Spacer, Stack } from '@chakra-ui/react';
import { ParticipantBehaviour, ParticipantWidgetLayoutItem } from '@piccolohealth/pbs-common';
import { P } from '@piccolohealth/util';
import React from 'react';
import { FaExpandArrowsAlt, FaPen, FaTrash } from 'react-icons/fa';
import { showModal } from '../../../../components/generic/Modal';
import { useDelayedLoading } from '../../../../hooks/useDelayedLoading';
import { UseGridReturn } from '../../hooks/useGrid';
import { TableWidgetEditModal } from '../table/TableWidgetEditModal';
import { TimeSeriesWidgetEditModal } from '../timeseries/TimeseriesWidgetEditModal';
import { TimeSeriesWidgetViewModal } from '../timeseries/TimeSeriesWidgetViewModal';
import { ParticipantWidgetDeleteModal } from './ParticipantWidgetDeleteModal';
import { TableGridItem } from './TableGridItem';
import { TimeSeriesGridItem } from './TimeSeriesGridItem';

interface ParticipantDataGridItemProps {
  item: ParticipantWidgetLayoutItem;
  participantId: string;
  participantBehaviours: ParticipantBehaviour[];
  grid: UseGridReturn<ParticipantWidgetLayoutItem>;
  isDisabled?: boolean;
}

export const ParticipantDataGridItem = (props: ParticipantDataGridItemProps) => {
  const { item, participantBehaviours, participantId, grid, isDisabled } = props;

  // Introduce a fake loading delay to simulate loading.
  // Echarts seems to glitch if load too quickly
  const isSkeletonLoading = useDelayedLoading(1);

  const onRemove = () => {
    showModal(ParticipantWidgetDeleteModal, {
      onDelete: () => grid.onRemove(item.id),
    });
  };

  const onEdit = (item: ParticipantWidgetLayoutItem) => {
    switch (item.widget.__typename) {
      case 'TimeSeriesChartWidget': {
        showModal(TimeSeriesWidgetEditModal, {
          participantId,
          widget: item.widget,
          onEdit: (widget) => grid.onEdit({ ...item, widget }),
        });
        break;
      }

      case 'TableWidget': {
        showModal(TableWidgetEditModal, {
          participantId,
          widget: item.widget,
          onEdit: (widget) => grid.onEdit({ ...item, widget }),
        });
      }
    }
  };

  const onMaximize = (item: ParticipantWidgetLayoutItem) => {
    switch (item.widget.__typename) {
      case 'TimeSeriesChartWidget':
        showModal(TimeSeriesWidgetViewModal, {
          participantId,
          widget: item.widget,
          startDate: grid.startDate,
          endDate: grid.endDate,
        });
    }
  };

  const content = P.run(() => {
    switch (item.widget.__typename) {
      case 'TimeSeriesChartWidget':
        return (
          <TimeSeriesGridItem
            widget={item.widget}
            participantBehaviours={participantBehaviours}
            startDate={grid.startDate}
            endDate={grid.endDate}
            onStartDateChange={grid.onStartDateChange}
            onEndDateChange={grid.onEndDateChange}
          />
        );
      case 'TableWidget':
        return (
          <TableGridItem
            widget={item.widget}
            participantBehaviours={participantBehaviours}
            startDate={grid.startDate}
            endDate={grid.endDate}
          />
        );
      default:
        return <Box>Not implemented yet - {item.widget.__typename}</Box>;
    }
  });

  return (
    <Stack w="full" h="full" spacing={0}>
      <HStack w="full" px={4} py={2} cursor="move" className="grid-stack-item-draghandle">
        <Heading size="sm">{item.widget.title}</Heading>
        <Spacer />
        <IconButton
          aria-label="Maximize"
          icon={<FaExpandArrowsAlt />}
          onClick={() => onMaximize(item)}
          variant="ghost"
          size="xs"
          zIndex={10}
          color="gray.500"
        />
        <IconButton
          aria-label="Edit"
          icon={<FaPen />}
          onClick={() => onEdit(item)}
          isDisabled={isDisabled}
          variant="ghost"
          size="xs"
          zIndex={10}
          color="gray.500"
        />
        <IconButton
          aria-label="Remove"
          icon={<FaTrash />}
          onClick={onRemove}
          isDisabled={isDisabled}
          variant="ghost"
          size="xs"
          zIndex={10}
          color="gray.500"
        />
      </HStack>
      <Divider />
      <Box
        w="full"
        h="full"
        opacity={isSkeletonLoading ? 0 : 1}
        transition="opacity 0.5s ease-in-out"
      >
        {content}
      </Box>
    </Stack>
  );
};
